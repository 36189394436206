import React, { Component } from 'react'
import axios from 'axios'
import '../Login/css/Forgot.css'
import { ToastContainer, toast } from "react-toastify";


export default class Forgotpass extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',

    }
  }

  updateEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  //   updateOtp = (e) =>{
  //       this.setState({[e.target.otp]:e.target.value})
  //   }

  sendOtp = () => {
    console.log(this.state);
    this.props.history.push({
      pathname: '/otp',
      state: { detail: this.state.email }
    });
  }

  forgetpassword = e => {
    e.preventDefault()
    console.log(this.state)
    axios.post('http://15.207.18.171:8080/Agriguru/v1/student/forgot-password', this.state)
      .then(res => {
        console.log(res);
        if (res.data.status === "200") {
          const uniqueNo = res.data.result;
          // alert(uniqueNo);
          toast.success(res.data.message)
          this.props.history.push('/Otp/' + uniqueNo);
        }
        else {
          toast.error(res.data.message)
        }

      })
      .catch(error => {
        console.log(error)
        toast.error(error)
      })
  }

  render() {
    // let {show} = this.state.otp;
    // if(otp === ) {
    return (
      <React.Fragment>
        <div className="form-gap" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-4">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3><i className="fa fa-lock fa-4x" /></h3>
                    <h2 className="text-center">Forgot Password?</h2>
                    <p>You can reset your password here.</p>
                    <div className="panel-body">
                      <form id="register-form" role="form" autoComplete="on" className="form" method="post" onSubmit={this.forgetpassword}>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon pr-4"><i className="glyphicon glyphicon-envelope color-blue" /></span>
                            <input id="email" name="email" placeholder="email address" className="form-control" type="email" onChange={this.updateEmail} />
                          </div>

                          {/* <label>OTP</label>
                          <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue" /></span>
                            <input id="otp" name="otp" placeholder="OTP" className="form-control" type="text" onChange={this.updateOtp} />
                          </div> */}
                        </div>
                        <div className="form-group">
                          {/* <input name="recover-submit" className="btn btn-lg btn-primary btn-block" defaultValue="Reset Password" type="submit" /> */}
                          <button type="submit" className="btn btn-primary">Send Otp</button>
                        </div>
                        {/* <input type="hidden" className="hide" name="token" id="token" defaultValue /> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer 
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      </React.Fragment>
    )
  }
}



