import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router';
import { ToastContainer, toast } from "react-toastify";

class Resetpassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      uniqueNo: this.props.match.params.uniqueNo
    }
  }
  updatePassword = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  changePassword = (e) => {
    e.preventDefault();
    axios.post('http://15.207.18.171:8080/Agriguru/v1/student/reset-password', this.state)
      .then(res => {
        console.log("response from resetpassword: ", res);
        if (res.data.status === "200") {
          toast.success(res.data.message)
          this.props.history.push('/login');
        }
        else {
          // alert(res.data.message);
          toast.error(res.data.message)
        }
      })
      .catch(error => {
        // console.log(error)
        toast.error(error)
      })
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="container">
          <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <form className="form-group" >
                <h2 className="ml-5">Enter Password</h2>
                <input id="password" name="password" placeholder="Enter Password Here.." className="form-control" type="password" onChange={this.updatePassword} />
              </form>
              <button className="btn btn-success ml-3" type="submit" onClick={this.changePassword}>Reset Password</button>
            </div>
            <div className="col-md-4">
            </div>
          </div>
        </div> */}
        <div className="form-gap" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-4">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3><i className="fa fa-lock fa-4x" /></h3>
                    <h2 className="text-center">Enter Password</h2>
                    <div className="panel-body">
                      <form id="register-form" role="form" autoComplete="on" className="form" method="post" onSubmit={this.checkOtp}>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon pr-4"><i className="fas fa-key color-blue" /></span>
                            <input id="password" name="password" placeholder="Enter Password Here.." className="form-control" type="password" onChange={this.updatePassword} />
                          </div>
                        </div>
                        <div className="form-group">
                          {/* <input name="recover-submit" className="btn btn-lg btn-primary btn-block" defaultValue="Reset Password" type="submit" /> */}
                          <button className="btn btn-success ml-3" type="submit" onClick={this.changePassword}>Reset Password</button>
                        </div>
                        <input type="hidden" className="hide" name="token" id="token" defaultValue />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer 
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      </React.Fragment>
    )
  }
}
export default withRouter(Resetpassword)