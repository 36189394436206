import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from 'material-table';

export default class ShowOneLiner extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            categories: [],
            selectedCategory: "",
            selectedSubCategory: "",
            validationError: "",
            subcategory: "",
            subCat: [],
            columns: [
                { title: 'question', field: 'question', initialEditValue: '' },
                { title: '', field: '', initialEditValue: '' },
                { title: 'answer', field: 'answer', initialEditValue: '' },
               
              ],
              data: [],
        }
    }

    componentDidMount() {
        axios.get('http://15.207.18.171:8080/Agriguru/v1/list-category')
          .then(res => {
            if (res.data.status === "200") {
              // console.log("result array: ", res.data.result);
              let categoriesFromApi = res.data.result.map(cat => {
                return { value: cat.categoryId, display: cat.category };
              });
              this.setState({
                categories: [
                  {
                    value: "",
                    display: "Select Category"
                  }
                ].concat(categoriesFromApi)
              });
            }
            else {
              toast.error(res.data.message);
            }
          })
          .catch(error => {
            toast.error(error);
          });
      }
    
      setCategory = (e) => {
        this.setState({
          selectedCategory: e.target.value,
          validationError:
            e.target.value === ""
              ? "You must select your favourite category"
              : ""
        })
        // console.log("setcategory",e.target.value)
        this.fetchSubcategory(e.target.value);
      }
    
    
      fetchSubcategory(selectedCategory) {
        // console.log("fetchsub: ", selectedCategory)
        if (selectedCategory !== '') {
          var url = 'http://15.207.18.171:8080/Agriguru/v1/category-wise-subcategory/' + selectedCategory;
          axios.get(url)
            .then(res => {
              if (res.data.status === "200") {
                // console.log("result array: ", res.data.result);
                let subCategoriesFromApi = res.data.result.map(cat => {
                  return { value: cat.subcategoryId, display: cat.subcatName };
                });
                console.log("subcategories from API: ", subCategoriesFromApi);
                this.setState({
                  subCat: [
                    {
                      value: "",
                      display: "Select Sub-Category"
                    }
                  ].concat(subCategoriesFromApi)
                });
              }
              else {
                toast.error(res.data.message);
              }
            })
            .catch(error => {
              toast.error(error);
            });
        }
      }
    
      setSubCategory = (e) => {
        this.setState({
          selectedSubCategory: e.target.value,
          validationError:
            e.target.value === ""
              ? "You must select your favourite sub category"
              : ""
        })
        // console.log("set sub category:",e.target.value)
      }

  

      show = () => {
          var pagination = {
            "pageNum":1,
            "numPerPage":10
          }
          var url = 'http://15.207.18.171:8080/Agriguru/v1/get_one_liners?subcategoryId='+this.state.selectedSubCategory;
          axios.post(url , pagination)
          .then(res => {
            this.setState({ data: res.data.result })
              console.log("show",res)
          })
      }
    
    render() {
        return (
            <React.Fragment>
                 <h5 className="text-muted pl-5 pt-4">Show OneLiner</h5>
          <div className="form-group">
            <div className="container pt-3 pb-5 mb-4">
              <div className="row"> 
                <div className="col-md-6">
                  <select
                    value={this.state.selectedCategory}
                    onChange={this.setCategory}
                    className="form-control">
                    {this.state.categories.map(cat => (
                      <option
                        key={cat.value}
                        value={cat.value}
                      >
                        {cat.display}
                      </option>
                    ))}
                  </select><br></br>
                  <button type="button" className="btn primary" onClick={this.show}>Show OneLiner</button>
                </div>
                <div className="col-md-6">
                  <select
                    value={this.state.selectedSubCategory}
                    onChange={this.setSubCategory}
                    className="form-control">
                    {this.state.subCat.map(cat => (
                      <option
                        key={cat.value}
                        value={cat.value}
                      >
                        {cat.display}

                      </option>
                    ))}
                  </select><br></br>
                </div>
                {/* <div className="col-md-4">
                </div> */}
                </div><br></br>
                
                <MaterialTable
                                title="Show Oneliner"
                                columns={this.state.columns}
                                data={this.state.data}
                                editable={{
                                    // onRowAdd: (newData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("From onRowAdd")
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const add = [...prevState.add];
                                    //         add.push(newData);
                                    //         {this.addCategoryData(newData)}
                                    //         return { ...prevState, add };
                                    //       });
                                    //       // { this.addCategoryData(newData) }
                                    //     }, 600);
                                    //   }),
                                    // onRowUpdate: (newData, oldData) =>
                                    //   new Promise((resolve) => {
                                    //     // console.log("Resolve: ", resolve);
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       if (oldData) {
                                    //         // console.log("oldData: ", oldData);
                                    //         // console.log("newData: ", newData);
                                    //         { this.updateCategoryList(newData) }
                                    //         this.setState((prevState) => {
                                    //           // console.log("prevState: ", prevState);
                                    //           const data = [...prevState.data];
                                    //           data[data.indexOf(oldData)] = newData;
                                    //           return { ...prevState, data };
                                    //         });
                                    //       }
                                    //     }, 600);
                                    //   }),
                                    // onRowDelete: (oldData) =>
                                    //   new Promise((resolve) => {
                                    //     setTimeout(() => {
                                    //       resolve();
                                    //       this.setState((prevState) => {
                                    //         const data = [...prevState.data];
                                    //         data.splice(data.indexOf(oldData), 1);
                                    //         return { ...prevState, data };
                                    //       });

                                    //     { this.DeleteCategoryList(oldData) }
                                    //     }, 600);
                                    //   }),
                                }}
                            />
                </div>
                </div>
            </React.Fragment>
        )
    }
}
